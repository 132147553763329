import Locale from "types/Locale";

export const POLYFILL_REQUIRED = !("Intl" in global);

export default async function intl(): Promise<void> {
  await import(/* webpackChunkName: "polyfill~intl" */ "intl/index.js");
}

export function intlLocaleData(locale: Locale) {
  return import(/* webpackChunkName: "polyfill~intl-locale-data~" */ `intl/locale-data/jsonp/${locale}`);
}
