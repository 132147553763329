import UAParser from "ua-parser-js";

import intlPolyfill, { POLYFILL_REQUIRED as INTL_POLYFILL_REQUIRED } from "./intl";

const { name: browserName = "" } = new UAParser().getBrowser();
const isIE = /IE/.test(browserName);

const polyfills: Array<[boolean, () => Promise<any>]> = [
  [
    !("assign" in Object),
    () => import(/* webpackChunkName: "polyfill~object-assign" */ "core-js/fn/object/assign"),
  ],
  [
    !("forEach" in NodeList.prototype),
    () => Promise.resolve(NodeList.prototype.forEach = Array.prototype.forEach as any),
  ],
  [
    INTL_POLYFILL_REQUIRED,
    intlPolyfill,
  ],
  [
    !("Map" in window) || isIE,
    () => import(/* webpackChunkName: "polyfill~map" */ "core-js/fn/map"),
  ],
  [
    !("Set" in window) || isIE,
    () => import(/* webpackChunkName: "polyfill~set" */ "core-js/fn/set"),
  ],
  [
    !("Symbol" in window),
    () => import(/* webpackChunkName: "polyfill~symbol" */ "core-js/fn/symbol"),
  ],
];

export default function load(): Promise<any> {
  return Promise.all(
    polyfills.reduce(
      (list, [polyfillRequired, polyfill]) => {
        return polyfillRequired ? [...list, polyfill()] : list;
      },
      [],
    ),
  );
}
